// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jUT3A_3TmOr8z0WVpnQZ{display:flex;flex-direction:column;justify-content:space-evenly;height:100%}.jUT3A_3TmOr8z0WVpnQZ .pWr6eiVm47U2Hdk_QyHw{margin-bottom:.5rem}.jUT3A_3TmOr8z0WVpnQZ .YP5fAPe4CWPh2lFszG64{text-decoration:underline;font-size:var(--hc-size-text-small);display:flex;flex-direction:row;justify-content:center}.jUT3A_3TmOr8z0WVpnQZ span{font-weight:var(--hc-font-weight-regular);font-size:var(--hc-size-text-extrasmall)}`, "",{"version":3,"sources":["webpack://./src/application/pages/Home/containers/Suggestions/SuggestionSlide/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,4BAAA,CACA,WAAA,CAEA,4CACE,mBAAA,CAGF,4CACE,yBAAA,CACA,mCAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,2BACE,yCAAA,CACA,wCAAA","sourcesContent":[".contentContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  height: 100%;\n\n  .title {\n    margin-bottom: 0.5rem;\n  }\n\n  .smallText {\n    text-decoration: underline;\n    font-size: var(--hc-size-text-small);\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n  }\n\n  span {\n    font-weight: var(--hc-font-weight-regular);\n    font-size: var(--hc-size-text-extrasmall);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": `jUT3A_3TmOr8z0WVpnQZ`,
	"title": `pWr6eiVm47U2Hdk_QyHw`,
	"smallText": `YP5fAPe4CWPh2lFszG64`
};
export default ___CSS_LOADER_EXPORT___;
