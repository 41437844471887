import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from 'src/ui/cosmos/Card';
import { CardBody } from 'src/ui/cosmos/Card/Body';
import { CardHeader } from 'src/ui/cosmos/Card/Header';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { useTranslation } from 'react-i18next';
import { useCreateFolder, useFolderLatest } from 'src/domain/Folder/hooks';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { BasicPromptModal } from 'src/ui/Modal/components/BasicPromptModal';
import { useCallback } from 'react';
import ContentLoader from 'src/ui/cosmos/ContentLoader';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import FolderItem from '../../components/FolderItem';
import styles from './styles.module.scss';
const LastFolders = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isLoading, data: folders } = useFolderLatest({ elementPerPage: 6 });
    const createFolder = useCreateFolder();
    const isEmpty = !isLoading && folders?.length === 0;
    const handleCreateFolder = useCallback(async () => {
        const name = await NiceModal.show(BasicPromptModal, {
            loading: createFolder.isLoading,
            title: t('modal.folder.create.title'),
            buttons: {
                save: {
                    label: t('general.create'),
                },
                cancel: {
                    label: t('general.cancel'),
                },
            },
        });
        await createFolder.mutateAsync({
            name,
            parentFolderCode: '',
        });
        NiceModal.hide(BasicPromptModal);
    }, [createFolder, t]);
    if (isLoading)
        return (_jsx(ContentLoader, { className: styles.contentLoader, "data-testid": 'content-loader' }));
    if (isEmpty)
        return (_jsx(Card, { className: styles.container, children: _jsxs("div", { className: styles.emptyContent, children: [_jsxs("div", { children: [_jsx(Heading, { type: 'h4', children: t('home.default_home.last_folders_card.empty_content.title') }), _jsx("p", { children: t('home.default_home.last_folders_card.empty_content.description') })] }), _jsx(Button, { size: 'medium', variation: 'secondary', context: 'neutral', onClick: () => {
                            handleCreateFolder();
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.HOME_CREATE_FIRST_FOLDER);
                        }, children: t('home.buttons.new_folder') })] }) }));
    return (_jsxs(Card, { className: styles.container, children: [_jsxs(CardHeader, { className: styles.header, children: [_jsx(Heading, { type: 'h3', size: 'small', children: t('home.default_home.last_folders_card.title') }), _jsxs("div", { className: styles.headerActions, children: [_jsx(Button, { size: 'medium', variation: 'tertiary', onClick: () => {
                                    navigate('library?filter=folders');
                                    EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.HOME_VIEW_ALL_FOLDERS);
                                }, children: t('home.buttons.see_all') }), _jsx(Button, { size: 'medium', variation: 'secondary', onClick: () => {
                                    handleCreateFolder();
                                    EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.HOME_NEW_FOLDER);
                                }, children: t('home.buttons.new_folder') })] })] }), _jsx(CardBody, { className: styles.body, children: folders?.map((folder) => {
                    return _jsx(FolderItem, { folder: folder }, folder.code);
                }) })] }));
};
export default LastFolders;
