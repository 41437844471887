import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import NiceModal from '@ebay/nice-modal-react';
import { MediaPreviewModal } from 'src/domain/Media/components/Modal/MediaPreview';
import { Card } from 'src/ui/cosmos/Card';
import { CardBody } from 'src/ui/cosmos/Card/Body';
import { CardHeader } from 'src/ui/cosmos/Card/Header';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { Trans, useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide, Autoplay, Navigation, Pagination, } from 'src/infrastructure/libs/Swiper';
import { Link } from 'react-router-dom';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { SuggestionSlide } from './SuggestionSlide';
import styles from './styles.module.scss';
const slideItems = Array.from({ length: 3 }, (_, i) => i + 1);
const Suggestions = () => {
    const { t } = useTranslation();
    const media = {
        code: process.env.MEDIA_CODE_VIDEO_AD,
        isPublic: true,
    };
    const openMediaPreview = (slide) => {
        NiceModal.show(MediaPreviewModal, {
            media,
            withDownloadButton: false,
        });
        EventPrepAndTrackService.sendDebouncedInteractClick(`${IntentEvent.HOME_SLIDE_ITEM_PREFIX}.${slide}`);
    };
    return (_jsxs(Card, { className: styles.container, children: [_jsx(CardHeader, { className: styles.header, children: _jsx(Heading, { type: 'h3', size: 'small', children: t('home.default_home.suggestions_card.title') }) }), _jsx(CardBody, { children: _jsxs(Swiper, { spaceBetween: 5, centeredSlides: true, cssMode: true, autoplay: {
                        delay: 15000,
                        disableOnInteraction: false,
                    }, loop: true, pagination: {
                        clickable: true,
                    }, navigation: true, modules: [Autoplay, Pagination, Navigation], className: styles.swiper, children: [slideItems.map((item) => (_jsx(SwiperSlide, { className: styles.swiperSlide, onClick: () => openMediaPreview(item), children: _jsx(SuggestionSlide, { title: _jsx(Trans, { i18nKey: `home.default_home.suggestions_card.slides.suggestion${item}`, components: { span: _jsx("span", {}), br: _jsx("br", {}) } }), smallText: t('home.default_home.suggestions_card.slides.know_more') }) }, String(item)))), _jsx(SwiperSlide, { className: styles.swiperSlide, children: _jsx(Link, { to: process.env.SUGGESTION_CARD_FORM_LINK, target: '_blank', onClick: () => EventPrepAndTrackService.sendDebouncedInteractClick(`${IntentEvent.HOME_SLIDE_ITEM_PREFIX}.4`), children: _jsx(SuggestionSlide, { title: _jsx(Trans, { i18nKey: 'home.default_home.suggestions_card.slides.suggestion4', components: { br: _jsx("br", {}) } }), smallText: t('home.default_home.suggestions_card.slides.access_here') }) }) })] }) })] }));
};
export default Suggestions;
